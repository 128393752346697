/* eslint-disable no-magic-numbers -- if all magic numbers were defined, file would be unreadable */
import type { ReactNode } from 'react'

import { motion } from 'framer-motion'

type Properties = {
    children?: ReactNode | ReactNode[]
}

export const AngledFloater = ({ children }: Properties): JSX.Element => {
    return (
        <motion.div
            animate={{
                rotateY: [-2, -10, -2],
                rotateX: [1, 3, 1, -3, 1],
                perspective: [1000, 1000, 1000, 1000, 1000],
            }}
            transition={{
                duration: 10,
                repeat: Infinity,
                stiffness: 100,
                damping: 10,
            }}
            className='relative z-0 w-full shadow-2xl'
        >
            {children}
        </motion.div>
    )
}

export type AngledFloater = ReturnType<typeof AngledFloater>
