import { Fragment, useCallback, useState } from 'react'

import classNames from 'classnames'

import { ChevronLeft, ChevronRight } from '@nick-mazuk/ui/elements/icon'
import { Text } from '@nick-mazuk/ui/elements/text'
import { YouTube } from '@nick-mazuk/ui/elements/video'
import { WithClick } from '@nick-mazuk/ui/hoc/with-click'

type Music = {
    youtube: string
    title: string
    caption: string
    description: string
}

const MUSIC_MAP: Music[] = [
    {
        youtube: 'uVe1REluvtI',
        title: 'Across Lots',
        caption: 'Across Lots',
        description: 'Winner: Christopher Bill Composition Competition, 2018',
    },
    {
        youtube: 'hNfjpX7Jj3A',
        title: 'B.E.A.M. for Trombone Choir',
        caption: 'B.E.A.M. for Trombone Choir',
        description: 'Played at the Central Coast Trombone Day, 2019',
    },
    {
        youtube: 'KHOht-6-KcE',
        title: 'Yosemite in Summer',
        caption: 'Yosemite in Summer',
        description: '3rd Place: Corwin Awards for large ensemble, 2018',
    },
    {
        youtube: 'xUqsz7jMgBE',
        title: 'Undercover',
        caption: 'Undercover',
        description: '3rd Place: Christopher Bill Composition Competition, 2019',
    },
]

const Dots = ({
    index,
    updateIndex,
}: {
    index: number
    updateIndex: (newIndex: number) => void
}): JSX.Element => {
    return (
        <div className='mt-4 text-center'>
            <div className='inline-block'>
                {MUSIC_MAP.map((music, musicIndex) => {
                    const classes = classNames('w-3 h-3 border border-gray-700 rounded-full', {
                        'bg-gray-700': musicIndex === index,
                        'group-hover:bg-gray-100': musicIndex !== index,
                    })
                    return (
                        <Fragment key={music.youtube}>
                            <WithClick
                                // eslint-disable-next-line react/jsx-no-bind -- arrow function necessary to pass parameter
                                callback={() => updateIndex(musicIndex)}
                                className='inline-block p-2 group'
                            >
                                <div className={classes} />
                            </WithClick>
                        </Fragment>
                    )
                })}
            </div>
        </div>
    )
}

export const MusicCarousel = (): JSX.Element => {
    const [index, setIndex] = useState(0)

    const updateIndex = useCallback((newIndex: number) => {
        if (newIndex < 0) setIndex(MUSIC_MAP.length - 1)
        else if (newIndex >= MUSIC_MAP.length) setIndex(0)
        else setIndex(newIndex)
    }, [])

    const decrementIndex = useCallback(() => updateIndex(index - 1), [index, updateIndex])
    const incrementIndex = useCallback(() => updateIndex(index + 1), [index, updateIndex])
    return (
        <>
            <div className='relative'>
                <div className='overflow-hidden rounded-lg shadow-md'>
                    <YouTube id={MUSIC_MAP[index].youtube} title={MUSIC_MAP[index].title} />
                </div>
                <WithClick
                    callback={decrementIndex}
                    className='absolute flex items-center justify-around w-12 h-12 duration-150 transform -translate-y-1/2 bg-white rounded-full shadow-lg cursor-pointer top-1/2 -left-6 hover:text-white hover:bg-primary transition-color'
                >
                    <ChevronLeft width='w-8' />
                </WithClick>
                <WithClick
                    callback={incrementIndex}
                    className='absolute flex items-center justify-around w-12 h-12 duration-150 transform -translate-y-1/2 bg-white rounded-full shadow-lg cursor-pointer top-1/2 -right-6 hover:text-white hover:bg-primary transition-color'
                >
                    <ChevronRight width='w-8' />
                </WithClick>
            </div>
            <div className='mt-6'>
                <Text small bold center>
                    {MUSIC_MAP[index].caption}
                </Text>
                <Text small center>
                    {MUSIC_MAP[index].description}
                </Text>
            </div>
            <Dots index={index} updateIndex={updateIndex} />
        </>
    )
}
