import { Fragment } from 'react'

import { Image } from '@nick-mazuk/ui/elements/image'
import { motion } from 'framer-motion'

// eslint-disable-next-line max-lines-per-function -- still very readable
export const LogoWheel = (): JSX.Element => {
    const images = []
    // eslint-disable-next-line no-magic-numbers -- 4 times covers even the largest screens
    for (let i = 0; i < 4; i++) {
        images.push(
            <Fragment key={i}>
                <span className='flex-none w-48 ml-24 logo-wheel-item'>
                    <Image
                        src='/images/news-logos/sb-independent.png'
                        alt='Santa Barbara Independent'
                        eager
                        width={422}
                        height={119}
                    />
                </span>
                <span className='flex-none w-64 ml-24 logo-wheel-item'>
                    <Image
                        src='/images/news-logos/finale-blog.png'
                        alt='Finale Blog'
                        eager
                        width={708}
                        height={116}
                    />
                </span>
                <span className='flex-none w-40 ml-24 logo-wheel-item'>
                    <Image
                        src='/images/news-logos/classical-trombone.png'
                        alt='Division Brass'
                        eager
                        width={601}
                        height={321}
                    />
                </span>
                <span className='flex-none w-48 ml-24 logo-wheel-item'>
                    <Image
                        src='/images/news-logos/scoring-notes.png'
                        alt='Scoring Notes'
                        eager
                        width={302}
                        height={109}
                    />
                </span>
                <span className='flex-none w-64 ml-24 logo-wheel-item'>
                    <Image
                        src='/images/news-logos/division-brass.png'
                        alt='Division Brass'
                        eager
                        width={573}
                        height={49}
                    />
                </span>
                <span className='flex-none w-48 ml-24 logo-wheel-item'>
                    <Image
                        src='/images/news-logos/of-note.png'
                        alt='OF NOTE'
                        eager
                        width={1068}
                        height={178}
                    />
                </span>
            </Fragment>
        )
    }
    return (
        <motion.div
            animate={{ translateX: 1823 }}
            transition={{ repeat: Infinity, duration: 60, ease: 'linear' }}
            className='relative z-50 flex flex-no-wrap items-center justify-around w-full logo-wheel'
        >
            {images}
        </motion.div>
    )
}

export type LogoWheel = ReturnType<typeof LogoWheel>
