export const Gear = (): JSX.Element => {
    return (
        <svg
            viewBox='0 0 146 146'
            xmlns='http://www.w3.org/2000/svg'
            style={{
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
            }}
            shapeRendering='geometricPrecision'
        >
            <g transform='matrix(1,0,0,1,-252.913,-183.703)'>
                <g transform='matrix(0.650306,0,0,0.650306,130.898,83.6141)'>
                    <g transform='matrix(1.44124,0,0,1.44124,-226.653,-207.612)'>
                        <path
                            d='M372.557,251.204C367.565,250.719 362.538,250.719 357.546,251.204L355.074,263.24C350.289,263.972 345.6,265.229 341.09,266.987L332.931,257.8C328.365,259.876 324.012,262.389 319.931,265.305L323.808,276.965C320.03,279.991 316.597,283.424 313.571,287.202L301.911,283.325C298.995,287.406 296.482,291.759 294.406,296.325L303.593,304.484C301.835,308.994 300.578,313.683 299.846,318.468L287.81,320.94C287.325,325.932 287.325,330.959 287.81,335.951L299.846,338.423C300.578,343.208 301.835,347.897 303.593,352.407L294.406,360.566C296.482,365.132 298.995,369.485 301.911,373.566L313.571,369.689C316.597,373.466 320.03,376.899 323.808,379.926L319.931,391.585C324.012,394.501 328.365,397.015 332.931,399.091L341.09,389.904C345.6,391.662 350.289,392.918 355.074,393.651L357.546,405.686C362.538,406.172 367.565,406.172 372.557,405.686L375.029,393.651C379.814,392.918 384.503,391.662 389.013,389.904L397.172,399.091C401.738,397.015 406.091,394.501 410.172,391.585L406.295,379.926C410.073,376.899 413.505,373.466 416.532,369.689L428.191,373.566C431.107,369.485 433.621,365.132 435.697,360.566L426.51,352.407C428.268,347.897 429.524,343.208 430.257,338.423L442.292,335.951C442.778,330.959 442.778,325.932 442.292,320.94L430.257,318.468C429.524,313.683 428.268,308.994 426.51,304.484L435.697,296.325C433.621,291.759 431.107,287.406 428.191,283.325L416.532,287.202C413.505,283.424 410.073,279.991 406.295,276.965L410.172,265.305C406.091,262.389 401.738,259.876 397.172,257.8L389.013,266.987C384.503,265.229 379.814,263.972 375.029,263.24L372.557,251.204ZM365.051,312.924C373.618,312.924 380.572,319.879 380.572,328.445C380.572,337.012 373.618,343.966 365.051,343.966C356.485,343.966 349.53,337.012 349.53,328.445C349.53,319.879 356.485,312.924 365.051,312.924Z'
                            className='fill-current'
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export type Gear = ReturnType<typeof Gear>
