import { formatNumber } from '@nick-mazuk/lib/text-styling'
import { Button } from '@nick-mazuk/ui/elements/button'
import { Tool, User } from '@nick-mazuk/ui/elements/icon'
import { Image } from '@nick-mazuk/ui/elements/image'
import { Link } from '@nick-mazuk/ui/elements/link'
import { Text } from '@nick-mazuk/ui/elements/text'
import { YouTube } from '@nick-mazuk/ui/elements/video'
import { CtaBox } from '@nick-mazuk/ui/marketing/components/cta-box'
import { FeatureText } from '@nick-mazuk/ui/marketing/components/feature-text'
import { StatBar } from '@nick-mazuk/ui/marketing/components/stat-bar'
import { ImageAndText } from '@nick-mazuk/ui/marketing/layouts/image-and-text'
import { AngledFloater } from 'components/angled-floater'
import { GearsTurning } from 'components/gears-turning'
import { Globe } from 'components/globe'
import { Laptop } from 'components/laptop'
import { Layout } from 'components/layout'
import { LogoWheel } from 'components/logo-wheel'
import { MusicCarousel } from 'components/music-carousel'

import { useYouTubeAnalytics } from 'lib/swr-requests'

export default function Home(): JSX.Element {
    const youtubeAnalytics = useYouTubeAnalytics()
    return (
        <Layout title='Home'>
            <section id='hero'>
                <div className='relative z-10 flex overflow-hidden origin-bottom-left transform -skew-y-12 bg-accent-cyan'>
                    <div className='absolute inset-x-0 min-h-screen bg-gradient-to-b from-primary-700 via-primary-400 to-accent-cyan' />
                    <div className='absolute inset-x-0 grid min-h-screen grid-cols-8 grid-rows-5'>
                        <div className='col-span-5 row-span-2 sm:row-span-1 sm:col-span-3 bg-gradient-to-r from-primary-700' />
                        <div className='col-span-6 col-start-3 row-start-4 bg-gradient-to-l from-primary-400' />
                        <div className='col-span-5 col-start-1 row-start-3 bg-gradient-to-r from-primary' />
                    </div>
                    <div className='relative z-10 w-full h-full mt-16 mb-16 text-white origin-bottom-left transform skew-y-12 sm:mt-32 md:mt-40 lg:mt-56 wrapper'>
                        <div className='flex flex-col max-w-lg space-y-4 md:max-w-2xl sm:space-y-8'>
                            <Text h1>Nick does music like no one else.</Text>
                            <Text large>
                                I believe people with a passion for music can change the world for
                                the better. I first craft memorable music that form emotional
                                connections with my listeners, then I help others do the same.
                            </Text>
                            <div className='mt-8 sm:mt-16'>
                                <Button value='Learn more' href='#more' />
                            </div>
                        </div>
                        <div className='-mt-16 -mb-32 -ml-16 -mr-64 pointer-events-none select-none sm:-mb-48 sm:-mt-32 md:-mb-64 md:-mt-48'>
                            <Image
                                src='/images/stork-tower-no-background.png'
                                alt='Nick playing the trombone'
                                eager
                                width={2400}
                                height={1798}
                                hideSkeleton
                            />
                        </div>
                    </div>
                </div>
                <div
                    id='more'
                    className='relative z-0 flex items-center w-full h-32 -mt-8 overflow-x-hidden sm:-mt-24 md:h-32 md:-mt-32 lg:h-56 lg:-mt-56'
                >
                    <div className='w-full text-right'>
                        <div className='wrapper'>
                            <Text small semibold color='text-gray'>
                                Featured by
                            </Text>
                        </div>
                        <div className='mt-4'>
                            <LogoWheel />
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-16 wrapper'>
                <Text h6 as='p' center color='text-gray'>
                    Step 1
                </Text>
                <Text h2 center>
                    Create great music
                </Text>
                <div className='w-32 mx-auto mt-6 border-b-2 border-primary' />
                <div className='grid max-w-4xl grid-cols-1 gap-4 mx-auto mt-8 md:grid-cols-2 md:gap-12'>
                    <Text>
                        Whether it's composing{' '}
                        <Link href='/music/across-lots' styled>
                            music that wins international awards
                        </Link>
                        , finalizing a commission for the{' '}
                        <Link href='/music/b-e-a-m' styled>
                            Central Coast Trombone Day
                        </Link>
                        , or doing an arrangement for the Ojai Pops Orchestra, everything starts
                        with great music.
                    </Text>
                    <Text>
                        And music should give you the feels. Music should be inspiring. Great music{' '}
                        <Link href='music/prologue' styled>
                            brings people together
                        </Link>
                        . That's why I strive to create the best music for musicians to play, and
                        audiences to listen to.
                    </Text>
                </div>
                <div className='max-w-4xl mx-auto mt-12'>
                    <MusicCarousel />
                </div>
            </section>
            <section className='mt-16 wrapper'>
                <Text h6 as='p' center color='text-gray'>
                    Step 2
                </Text>
                <Text h2 center>
                    Improve the process
                </Text>
                <div className='w-32 mx-auto mt-6 border-b-2 border-primary' />
                <div className='grid max-w-4xl grid-cols-1 gap-4 mx-auto mt-8 -mb-16 md:grid-cols-2 md:gap-12'>
                    <Text>
                        Creating great music takes a lot of time. That's why with every piece I
                        create, I refine the process. If you create a process that always creates
                        great music, you will always write great music by using the process.
                    </Text>
                    <Text>
                        I take a modern approach. If it can be automated, automate it. Because
                        creating music should be easy. You should be free from tedious tasks so you
                        can focus on what matters: the music.
                    </Text>
                </div>
                <ImageAndText
                    reversed
                    image={<GearsTurning />}
                    text={
                        <div className='flex flex-col max-w-lg mx-auto space-y-8 md:max-w-md md:mx-0'>
                            <div className='flex flex-col space-y-2'>
                                <Text h4 as='h3'>
                                    Automate everything
                                </Text>
                                <Text color='text-gray'>
                                    If it can be automated, automate it. Code is much faster and
                                    more reliable that we can ever be. Let's let computers do the
                                    heavy lifting.
                                </Text>
                            </div>
                            <div className='flex flex-col space-y-2'>
                                <Text h4 as='h3'>
                                    Simplify everything
                                </Text>
                                <Text color='text-gray'>
                                    If you can't understand what's going on, make it simpler. If
                                    you're juggling 20 ideas in your head, it only slows you down.
                                </Text>
                            </div>
                            <div className='flex flex-col space-y-2'>
                                <Text h4 as='h3'>
                                    Iterate on everything
                                </Text>
                                <Text color='text-gray'>
                                    You're never going to get it right the first time. But by
                                    constantly iterating, you'll create better music than you
                                    thought was possible.
                                </Text>
                            </div>
                        </div>
                    }
                />
            </section>

            <section className='mt-16 wrapper'>
                <Text h6 as='p' center color='text-gray'>
                    Step 3
                </Text>
                <Text h2 center>
                    Redefine how music is made
                </Text>
                <div className='w-32 mx-auto mt-6 border-b-2 border-primary' />
                <div className='max-w-2xl mx-auto mt-4'>
                    <Text center>
                        Music is not a solo activity. So when you've developed something great,
                        share it with the world. That's why with every innovation I develop to
                        create better music, I share it with composers around the world. Then we can
                        all make great music, together.
                    </Text>
                </div>
                <ImageAndText
                    text={
                        <FeatureText
                            kicker='Finale Superuser'
                            title='Use Finale like a pro'
                            body={
                                "Finale is the industry standard music notation software. Yet it's incredibly complicated to use. That's why thousands of composers rely on my tutorials and innovations to help them write better music."
                            }
                            cta={[
                                {
                                    value: 'Sign up for free',
                                    href: 'https://partsandscore.com/sell',
                                },
                                {
                                    value: 'Learn more',
                                    href: 'https://partsandscore.com/sell',
                                },
                            ]}
                        />
                    }
                    image={
                        <Laptop animate>
                            <YouTube id='dBof6dPaXSA' title='5 rules of Finale workflow' noRatio />
                        </Laptop>
                    }
                    reversed
                />
                <ImageAndText
                    text={
                        <FeatureText
                            kicker='Parts & Score'
                            title='Sell, promote, and protect your music'
                            body='Over 100 composers from around the world use Parts and Score. Why? This sheet music distributor has the highest royalties. The fastest payouts. The best user interface. And the most detailed analytics. Everything that was hard is now easy.'
                            cta={[
                                {
                                    value: 'Sign up for free',
                                    href: 'https://partsandscore.com/sell',
                                },
                                {
                                    value: 'Learn more',
                                    href: 'https://partsandscore.com/sell',
                                },
                            ]}
                        />
                    }
                    image={
                        <div className='max-w-md mx-auto'>
                            <AngledFloater>
                                <Image
                                    src='/images/yosemite-in-summer.png'
                                    alt='Yosemite in Summer'
                                />
                            </AngledFloater>
                        </div>
                    }
                />
            </section>
            <section className='relative pt-8 mt-40'>
                <div className='absolute inset-0 z-0 transform -skew-y-3 opacity-100 bg-accent-yellow' />
                <div className='relative z-0 py-12 wrapper'>
                    <Text h6 as='p' large center color='text'>
                        Because
                    </Text>
                    <Text h2 center>
                        Music can change the world
                    </Text>
                    <div className='w-32 mx-auto mt-6 border-b-2 border-white' />
                </div>
                <p className='wrapper'>
                    <Globe />
                </p>
            </section>
            <div className='-mt-8'>
                <StatBar
                    stats={[
                        {
                            value: youtubeAnalytics?.viewCount
                                ? formatNumber(youtubeAnalytics.viewCount)
                                : '--',
                            label: 'YouTube views',
                        },
                        {
                            value: '2,500+',
                            label: 'Pieces on Parts and Score',
                        },
                        {
                            value: '6,000+',
                            label: 'Composers helped each month',
                        },
                    ]}
                    color='primary'
                />
            </div>

            <section className='wrapper'>
                <div className='relative z-10 grid gap-8 mt-16 md:grid-cols-2'>
                    <CtaBox
                        title='Learn more about Nick'
                        style='primary'
                        icon={<User />}
                        href='/about'
                    >
                        Check out what Nick's listening to on Spotify, how he views the world, and
                        read another boring bio.
                    </CtaBox>
                    <CtaBox
                        title='Explore the resources'
                        style='success'
                        icon={<Tool fill />}
                        href='/resources'
                    >
                        There's so much more to cover. See every resource Nick's created to help
                        people create and share great music.
                    </CtaBox>
                </div>
            </section>
        </Layout>
    )
}
