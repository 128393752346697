export const CircularArrows = (): JSX.Element => {
    return (
        <svg
            viewBox='0 0 274 124'
            xmlns='http://www.w3.org/2000/svg'
            style={{
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
            }}
            shapeRendering='geometricPrecision'
        >
            <g transform='matrix(1,0,0,1,-201.468,-203.591)'>
                <g transform='matrix(1.07086,0,0,1.07086,-38.5548,90.3127)'>
                    <g transform='matrix(0.933828,-0,-0,0.933828,224.14,105.783)'>
                        <path
                            d='M27.293,93.37L25.69,106.69L15.996,97.415L27.293,93.37Z'
                            className='fill-current'
                        />
                        <path
                            d='M25.69,16.971C19.931,31.225 16.971,46.456 16.971,61.83C16.971,73.998 18.825,86.076 22.454,97.652'
                            style={{ fill: 'none', strokeWidth: '4px' }}
                            className='stroke-current'
                        />
                    </g>
                </g>
                <g transform='matrix(1.07086,0,0,1.07086,-38.5548,90.3127)'>
                    <g transform='matrix(0.933828,-0,-0,0.933828,224.14,105.783)'>
                        <path
                            d='M246.151,30.291L247.754,16.971L257.448,26.245L246.151,30.291Z'
                            className='fill-current'
                        />
                        <path
                            d='M247.754,106.69C253.513,92.435 256.474,77.205 256.474,61.83C256.474,49.662 254.619,37.584 250.99,26.009'
                            style={{ fill: 'none', strokeWidth: '4px' }}
                            className='stroke-current'
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export type CircularArrows = ReturnType<typeof CircularArrows>
