import { ease } from '@nick-mazuk/lib/animation'
import { Ratio } from '@nick-mazuk/ui/elements/ratio'
import { CircularArrows } from 'components/circular-arrows'
import { Gear } from 'components/gear'
import { motion } from 'framer-motion'

const ANIMATION_INTERVAL = 10
const ANIMATION_DURATION = 3
const ARROW_ROTATION_ANGLE = -180
const LARGE_GEAR_ROTATION = 210
const MEDIUM_GEAR_ROTATION = -LARGE_GEAR_ROTATION * 2
// eslint-disable-next-line no-magic-numbers -- 4 is a gear ratio
const SMALL_GEAR_ROTATION = -LARGE_GEAR_ROTATION * 4

const transition = {
    repeat: Infinity,
    duration: ANIMATION_INTERVAL,
    times: [0, ANIMATION_DURATION / ANIMATION_INTERVAL, 1],
    ease: ease['ease-in-out-quad'],
}

export const GearsTurning = (): JSX.Element => {
    return (
        <Ratio ratio='1x1'>
            <div className='grid items-center justify-center grid-cols-12 grid-rows-12'>
                <motion.div
                    animate={{ rotate: [0, LARGE_GEAR_ROTATION, LARGE_GEAR_ROTATION] }}
                    transition={transition}
                    className='col-span-8 col-start-3 row-start-3 text-gray-100 row-span-8'
                >
                    <Gear />
                </motion.div>
                <motion.div
                    animate={{ rotate: [0, MEDIUM_GEAR_ROTATION, MEDIUM_GEAR_ROTATION] }}
                    transition={transition}
                    className='col-span-4 col-start-2 row-span-4 row-start-8 text-gray-50'
                >
                    <Gear />
                </motion.div>
                <motion.div
                    animate={{ rotate: [0, SMALL_GEAR_ROTATION, SMALL_GEAR_ROTATION] }}
                    transition={transition}
                    className='col-span-2 col-start-9 row-span-2 text-gray-200 row-start-9'
                >
                    <Gear />
                </motion.div>
                <motion.div
                    animate={{ rotate: [0, ARROW_ROTATION_ANGLE, ARROW_ROTATION_ANGLE] }}
                    transition={transition}
                    className='col-span-12 col-start-1 row-start-1 row-span-12 text-primary'
                >
                    <CircularArrows />
                </motion.div>
            </div>
        </Ratio>
    )
}

export type GearsTurning = ReturnType<typeof GearsTurning>
