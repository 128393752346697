import dynamic from 'next/dynamic'

import { Ratio } from '@nick-mazuk/ui/elements/ratio'

const GlobeElement = dynamic(
    () => import('./globe-element').then((module_) => module_.GlobeElement).catch((error) => error),
    { ssr: false }
)

export const Globe = (): JSX.Element => {
    return (
        <Ratio customRatio={0.37}>
            <div>
                <GlobeElement />
            </div>
        </Ratio>
    )
}
